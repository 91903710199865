import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Nav = () => {
  return (
    <Navbar>
      <NavLink to='/'>Home</NavLink>
      <NavLink to='/schedule'>Schedule</NavLink>
      <NavLink to='/leaderboard'>Leaderboard</NavLink>
    </Navbar>
  )
}

const Navbar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items:center;
  min-height: 6vh;
  position: sticky;
`
const NavLink = styled(Link)`
  color: #ffffff;
`

export default Nav