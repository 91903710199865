import React from 'react'
import styled from 'styled-components'
import Leaderboard from './Leaderboard'

import Schedule from './Schedule'

const Home = () => {
  return (
    <HomeWrapper>
      <Schedule />
      <Leaderboard />
    </HomeWrapper>
  )
}

const HomeWrapper = styled.div`
`

export default Home