import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Nav from './Nav'
import MobileNav from './MobileNav'

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <HeaderContainer>
      {windowWidth > 600 ? <Nav /> : <MobileNav />}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  background-color: #1d6422;
  box-shadow: inset 0vw -5vw 1vw -5vw #ffeb3b;
  top: 0;
`

export default Header