import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './theme/GlobalStyle'
import Theme from './theme/Theme'

import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  
    <ThemeProvider theme = { Theme }>
      <GlobalStyle />
      <App />
    </ThemeProvider>
 
)