import React, { useState } from 'react'
import styled from 'styled-components'

import tempscorecardbg from '../assets/images/tempscorecardbg.jpg'
import PuttingEdge from './courses/PuttingEdge'
import Sportway from './courses/Sportway'
import Putterz from './courses/Putterz'
import Paradise from './courses/Paradise'
import CJBarrymore from './courses/CJBarrymore'
import SportNFun from './courses/SportNFun'

import data from '../assets/data/data.json'
import '../assets/styles/leaderboard.css'

const Leaderboard = () => {
	const [currentTab, setCurrentTab] = useState(PuttingEdge)
	const [currentTabName, setCurrentTabName] = useState('1')

  const createButton = (prop, name) => {
		if (currentTabName===name) {
			return <TabActiveButton onClick={() => handleClick(prop, name)}>{name}</TabActiveButton>
		}
		return <TabButton onClick={() => handleClick(prop, name)}>{name}</TabButton>
	}
	const handleClick = (prop, name) => {
		setCurrentTab(prop)
		setCurrentTabName(name)
	}

  return (
    <LeaderboardWrapper className='leaderboard wrapper'>
      <LeaderboardContainer className='leaderboard container'>
        <LeaderboardContent className='leaderboard content'>
          <LeaderboardTable className='leaderboard table'>
            <LeaderboardButtons className='leaderboard-buttons'>Round</LeaderboardButtons>
            {createButton(PuttingEdge, '1')}
            {createButton(Sportway, '2')}
            {createButton(Putterz, '3')}
            {createButton(Paradise, '4')}
            {createButton(CJBarrymore, '5')}
            {createButton(SportNFun, '6')}
          </LeaderboardTable>
          {data.courses[currentTabName-1].course}
          <CourseScores className='leaderboard course-scores'>
            {currentTab}
            <LeaderboardPlayers className='leaderboard players'>
              {data.players.map((player) => {
                return (
                  <LeaderboardPlayer className='leaderboard player' key={player.name}>
                    <PlayerName className='leaderboard table-cell player-name'>
                      {player.name}
                    </PlayerName>
                    {player.round[currentTabName].map((score, i) => {
                      return (
                        <Score className='leaderboard table-cell player-score' key={player.name + i + score}>
                          {score !== 0 ? score - data.courses[currentTabName-1].pars[i]  : '' }
                        </Score>
                      )
                    })}
                  </LeaderboardPlayer>
                )
              })}
            </LeaderboardPlayers>
          </CourseScores>
        </LeaderboardContent>
      </LeaderboardContainer>
    </LeaderboardWrapper>
  )
}

const LeaderboardWrapper = styled.div`
  padding: 5vw;
  background: url(${tempscorecardbg}) ;
  background-size: cover;
  box-shadow: inset 0vw 0vw 5vw 0vw #00000085, inset 0vw 0vw 0.75vw 0.5vw #ffffff85;
`
const LeaderboardContainer = styled.div`
  background-color: rgba(255, 255, 255, .7);
  padding: 2vw;
`
const LeaderboardContent = styled.div`
`
const LeaderboardTable = styled.div`
	display:flex;
	flex-direction: row;
`
const TabButton = styled.button`
  background: none;
  padding: 2vw;
`
const LeaderboardButtons = styled.span`
  padding: 2vw;
`
const TabActiveButton = styled.button`
  background: none;
  margin: 2vw;
  border-bottom: 3px solid green;
`
const CourseScores = styled.div`
  overflow-x: scroll;
`
const LeaderboardPlayers = styled.div`
  display: flex;
  flex-direction: column;
`
const LeaderboardPlayer = styled.div`
  display: flex;
  flex-direction: row;
`
const PlayerName = styled.div`
  border: 1px solid green;
  padding: 1vw;
  min-width: 15vw;
`
const Score = styled.div`
  border: 1px solid green;
  padding: 1vw;
  min-width: 7vw;
  text-align: center;
`

export default Leaderboard