import React from 'react'
import styled from 'styled-components'

import data from '../assets/data/data.json'
import tempbg from '../assets/images/tempbg.jpg'
import '../assets/styles/schedule.css'

const Schedule = () => {
  return (
    <ScheduleWrapper className='schedule-wrapper'>
      <ScheduleContainer className='schedule-container'>
        <ScheduleH3 className='schedule-h3'>2022 Tournament Schedule</ScheduleH3>
        <ScheduleSubheading className='schedule-subheading'>
          All events start at 4:00 pm.
        </ScheduleSubheading>
        <ScheduleContent className='schedule-content'>
          <ScheduleTableHead className='schedule-table-header round'>Round</ScheduleTableHead>
          <ScheduleTableHead className='schedule-table-header date'>Date</ScheduleTableHead>
          <ScheduleTableHead className='schedule-table-header course'>Course</ScheduleTableHead>
          <ScheduleTableHead className='schedule-table-header holes'>Holes</ScheduleTableHead>
          <ScheduleTableHead className='schedule-table-header location'>Location</ScheduleTableHead>
          {data.courses.map((round, i) => {
            return (
              <React.Fragment key={round.round}>
                <ScheduleTableCell className='schedule-table-cell round'>
                  {i + 1}
                </ScheduleTableCell>
                <ScheduleTableCell className='schedule-table-cell date'>
                  {round.date}
                </ScheduleTableCell>
                <ScheduleTableCell className='schedule-table-cell course'>
                  <CourseLink href={round.url}>
                    {round.course}
                  </CourseLink>
                </ScheduleTableCell>
                <ScheduleTableCell className='schedule-table-cell holes'>
                  {round.holes}
                </ScheduleTableCell>
                <ScheduleTableCell className='schedule-table-cell location'>
                  {round.location['street-address']} {round.location['city']}
                </ScheduleTableCell>
              </React.Fragment>
            )
          })}
        </ScheduleContent>
      </ScheduleContainer>
    </ScheduleWrapper>
  )
}

const ScheduleWrapper = styled.div`
  background: url(${tempbg});
  padding: 5vw;
  background-size: cover;
  box-shadow: inset 0vw 0vw 5vw 0vw #00000085, inset 0vw 0vw 0.75vw 0.5vw #ffffff85;
`
const ScheduleContainer = styled.div`
  background-color: rgba(255, 255, 255, .7);
  padding: 2vw;
`
const ScheduleH3 = styled.h3`
  text-align: center;
  margin-bottom: 2vw;
`
const ScheduleSubheading = styled.p`
  text-align: center;
  font-size: .8em;
  padding-bottom: 2vw;
`
const ScheduleContent = styled.div`
  display: grid;
  grid-template-columns: .4fr .6fr 1fr .5fr 1fr;
  column-gap: 2vw;
  overflow: auto;

  @media screen and (max-width: 600px) {
    grid-template-columns: .6fr 1fr 1fr;
  }
`
const ScheduleTableHead = styled.h4`
`
const ScheduleTableCell = styled.div`
`
const CourseLink = styled.a`
  color: navy;
  &:hover {
    color: red;
  }
`

export default Schedule