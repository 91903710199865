import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from '../pages/Home'
import Leaderboard from '../pages/Leaderboard'
import Schedule from '../pages/Schedule'

const Main = () => {
  return (
    <Routes>
      <Route path = '/krombini-open//*' element = { <Main /> } />
      <Route index element = { <Home /> } />
      <Route path = '/leaderboard' element = { <Leaderboard /> } />
      <Route path = '/schedule' element = { <Schedule /> } />
    </Routes>
  )
}

export default Main