import React from 'react'
import styled from 'styled-components'

import data from '../../assets/data/data.json'

const Putterz = () => {
  return (
    <Score>
      <HoleHeader>
        <HPHeader>Hole</HPHeader>
        <HPHeader>Par</HPHeader>
      </HoleHeader>
      {data.courses[2].pars.map((par, hole) => {
        return (
          <HoleGroup key={hole}>
            <NumContainer>{hole+1}</NumContainer>
            <NumContainer>{par}</NumContainer>
          </HoleGroup>
        )
      })}
    </Score>
  )
}

const Score = styled.div`
  display: flex;
  flex-direction: row;
`
const HoleHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 15vw;
`
const HPHeader = styled.div`
  border: 1px solid green;
  padding: 1vw;
  min-width: 6vw;
`
const HoleGroup = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`
const NumContainer = styled.div`
  border: 1px solid green;
  padding: 1vw;
  min-width: 7vw;
`

export default Putterz

