import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
      </FooterContainer>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #1d6422;
  padding: 1vw;
  box-shadow: inset 0vw 5vw 1vw -5vw #ffeb3b;
  min-height: 1vh;
  width: 100%;
`
const FooterContainer = styled.div`
  text-align: center;
  font-size: .9rem;
  color: white;
`

export default Footer