import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FaBars, FaTimes } from 'react-icons/fa'

const MobileNav = () => {

  const [navStatus, setNavStatus] = useState(false)

  const NavPanel = () => {
    return (
      <NavPanelContainer>
        <OutsideMenu onClick={exitMenu}/>
        <Navbar>
          <CloseMenuButton onClick={handleToggle}>
            { navStatus ? <FaTimes /> : null }
          </CloseMenuButton>
          <NavLink to='/' onClick={exitMenu}>Home</NavLink>
          <NavLink to='/schedule' onClick={exitMenu}>Schedule</NavLink>
          <NavLink to='/leaderboard' onClick={exitMenu}>Leaderboard</NavLink>
        </Navbar>
      </NavPanelContainer>
    )
  }
  const handleToggle = () => {
    setNavStatus(!navStatus)
  }
  const exitMenu = () => {
    if(navStatus === true) {
      setNavStatus(false)
    }
  }

  return (
    <NavbarContainer>
      <OpenMenuButton onClick={handleToggle}>
        { !navStatus ? <FaBars /> : null }
      </OpenMenuButton>
      { navStatus ? <NavPanel />: null }
    </NavbarContainer>
  )
}

const NavbarContainer = styled.div`
  min-height: 6vh;
  position: sticky;
`

const OutsideMenu = styled.div`
  position: fixed;
  left:0;
  width:40%;
  height: 100%;
  background: black;
  opacity:50%;
`
const OpenMenuButton = styled.button`
  position: fixed;
  top: 1.5vh;
  right 4vw;
  background: none;
  color: white;
`
const CloseMenuButton = styled.button`
  position: fixed;
  top: 1.5vh;
  right 4vw;
  background-color: #1d6422;
  color: white;
`
const NavPanelContainer = styled.div`
`
const Navbar = styled.nav`
  position: fixed;
  right: 0;
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #1d6422;
  box-shadow: inset 5vw 0vw 1vw -5vw #ffeb3b;
  padding-top: 8vh;
`
const NavLink = styled(Link)`
  color: #ffffff;
  padding: 2vh 10vw;
  width: 100%;
`

export default MobileNav